<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png"
             alt />
        <span class="center">发票管理</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">发票列表</span>
      </div>
    </div>
    <div style="display: flex; align-items: center;justify-content: space-between;padding:10px 0"
         v-if="roles==='upper'">
      <div style="display: flex; align-items: center">
        <span class="seach_text">销售方：</span>
        <el-select v-model="enterpriseCode"
                   style="width: 230px"
                   size="mini"
                   clearable
                   filterable
                   :filter-method="goenterprise">
          <el-option v-for="(item, index) in stateList"
                     :key="index"
                     :label="item.name"
                     :value="item.code"></el-option>
        </el-select>

        <el-button size="mini"
                   type="primary"
                   style="margin-left:10px"
                   @click="loadData('seath')">查询</el-button>
      </div>
    </div>
    <div style="display: flex; align-items: center;justify-content: space-between;padding:10px 0"
         v-else>
      <div style="display: flex; align-items: center">
        <span class="seach_text">购买方：</span>
        <el-select v-model="upperCode"
                   style="width: 230px"
                   size="mini"
                   clearable>
          <el-option v-for="(item, index) in stateList"
                     :key="index"
                     :label="item.name"
                     :value="item.code"></el-option>
        </el-select>
        <span class="seach_text"
              style="margin-left:10px">发票状态：</span>
        <el-select v-model="isUpload"
                   style="width: 230px"
                   size="mini"
                   clearable>
          <el-option label="全部"
                     value="2"></el-option>
          <el-option label="已上传"
                     :value="true"></el-option>
          <el-option label="未上传"
                     :value="false"></el-option>
        </el-select>
        <el-button size="mini"
                   type="primary"
                   style="margin-left:10px"
                   @click="loadData('seath')">查询</el-button>
      </div>
      <el-button style="margin-left:20px"
                 type="primary"
                 v-access
                 data-ctrl="upperEnterpriseInvoiceAdd"
                 size="small"
                 @click="addProject()">新建</el-button>
    </div>
    <el-table :data="tableData"
              style="width: 100%">
      <el-table-column label="销售方"
                       v-if="roles==='upper'">
        <template slot-scope="scope">{{ scope.row.enterpriseName }}</template>
      </el-table-column>
      <el-table-column label="购买方"
                       v-else>
        <template slot-scope="scope">{{ scope.row.upperName }}</template>
      </el-table-column>
      <el-table-column label="关联工资包数">
        <template slot-scope="scope">
          <span>{{ scope.row.taskNum }}</span>
        </template>
      </el-table-column>
      <el-table-column label="快递公司">
        <template slot-scope="scope">
          <span>{{ scope.row.expressName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="快递单号">
        <template slot-scope="scope">
          <span>{{ scope.row.expressCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发票状态"
                       v-if="roles!='upper'">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isUpload"
                  type="success">已上传</el-tag>
          <el-tag v-else>未上传</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div v-if="roles === 'upper'">
            <el-tooltip class="item"
                        effect="dark"
                        content="点击查看图片"
                        placement="top-start">
              <el-button @click="showImg(scope.row.imgUrl, '查看照片')"
                         type="text">查看照片</el-button>
            </el-tooltip>
            <el-button @click="goDetail(scope.row.code)"
                       type="text">详情</el-button>
          </div>
          <div v-else>
            <el-button @click="goedit(scope.row.code)"
                       type="text">编辑</el-button>
            <el-popconfirm title="是否删除这条记录吗？"
                           @confirm="godel(scope.row.code)">
              <el-button slot="reference"
                         type="text"
                         style="color:red;margin-left:10px">刪除</el-button>
            </el-popconfirm>
            <el-tooltip class="item"
                        effect="dark"
                        content="点击查看图片"
                        placement="top-start">
              <el-button @click="showImg(scope.row.imgUrl, '查看照片')"
                         type="text">查看照片</el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor"
            style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination background
                     :current-page.sync="pageNumber"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-size="pageSize"
                     prev-text="上一页"
                     next-text="下一页"
                     layout="prev,pager, next,jumper"
                     :total="total"></el-pagination>
    </div>
    <el-dialog class="showImgDialog"
               width="680px"
               :center="true"
               :title="dialogTitle"
               :visible.sync="showImgVisible"
               :append-to-body="true">
      <div class="imgBox">
        <img :src="dialogImgUrl"
             class="img-resource"
             alt />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const upperEnterpriseInvoiceList = api()('upper_enterprise.invoice.list.json');
export const upperEnterpriseList = api()('upper_enterprise.list.json');
export const upperEnterpriseInvoiceDel = api()('upper_enterprise.invoice.del.json');
export const enterpriseList = api()('enterprise.list.json');


export default {
  data () {
    return {
      tableData: [

      ],
      keywords: '',
      dialogTitle: '',
      enterpriseCode: '',
      showImgVisible: false,
      dialogImgUrl: '',
      upperCode: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      isUpload: '',
      roles: '',
      stateList: []
    }
  },
  created () {
    this.roles = window.localStorage.getItem('roles')
    if (this.roles === 'upper') {
      this.goenterpriseList()
    }
    else {
      this.getEnterpriseList()
    }
    this.loadData()

  },
  methods: {
    goenterpriseList () {
      const params = {

        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      enterpriseList(params).then((res) => {
        this.stateList = res.list;
      });
    },
    goenterprise (keywords) {
      const params = {
        keywords: keywords,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      enterpriseList(params).then((res) => {
        this.stateList = res.list;
      });
    },
    showImg (img, title) {
      this.dialogImgUrl = img;
      this.dialogTitle = title;
      console.log(this.dialogImgUrl);
      this.showImgVisible = true;
    },
    getEnterpriseList () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      upperEnterpriseList(params).then((res) => {
        this.stateList = res.list;
      });
    },
    addProject () {
      this.$router.push({
        path: '/system/invoice/add',
      });
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData (seath) {

      var params = {}
      if (seath) {
        if (this.isUpload === '2' || this.isUpload === '') {
          console.log(this.keywords, seath, 2)
          params = {
            pageSize: this.pageSize,
            pageNumber: 1,
            upperCode: this.upperCode,
            enterpriseCode: this.enterpriseCode,
          }
        }

        else {
          console.log(this.keywords, seath, 3)
          params = {
            pageSize: this.pageSize,
            pageNumber: 1,
            enterpriseCode: this.enterpriseCode,
            upperCode: this.upperCode,
            isUpload: this.isUpload
          };
        }
      }
      else {
        params = {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber
        };
      }
      upperEnterpriseInvoiceList(params).then((res) => {
        for (let i = 0; i < res.list.length; i++) {
          res.list[i].passShow = false
        }
        this.tableData = res.list;
        console.log(this.tableData)
        this.total = res.total;
      });
    },
    goShow (code) {

      this.tableData.forEach(it => {
        if (it.code === code) {
          console.log(it)
          it.passShow = !it.passShow
        }
      })

    },
    godel (code) {
      const params = {
        code: code
      };
      upperEnterpriseInvoiceDel(params).then(() => {
        this.$message({
          message: '删除成功',
          type: 'info',
        });
        this.loadData()
      });
    },
    goedit (code) {
      this.$router.push({
        path: '/system/invoice/add',
        query: { code },
      });
    },
    goDetail (code) {
      this.$router.push({
        path: '/system/invoice/detail',
        query: { code },
      });
    }

  },
}
</script>
<style lang="scss" scope>
.showImgDialog .imgBox {
  display: flex;
}
.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.font-img {
  font-size: 26px;
  cursor: pointer;
  color: cadetblue;
}
</style>